import PropTypes from 'prop-types';
import { stringOrArrayOfStrings, validatesNumericOrNumericArrayType } from 'generic/utils/propTypeUtils';

const qesdocumentPropType = PropTypes.shape({
  BASE_ID: stringOrArrayOfStrings.isRequired,
  DATE_COLLECT: stringOrArrayOfStrings.isRequired,
  QUOTE_URL: stringOrArrayOfStrings,
  SOURCE: stringOrArrayOfStrings,
  Title: stringOrArrayOfStrings.isRequired,
  Text: stringOrArrayOfStrings.isRequired,
  URL: stringOrArrayOfStrings,
  idext: stringOrArrayOfStrings.isRequired,
  IDQES: stringOrArrayOfStrings.isRequired,
  ETAT: validatesNumericOrNumericArrayType,
});

const documentPropType = PropTypes.shape({
  idext: stringOrArrayOfStrings.isRequired,
  qesdocument: qesdocumentPropType,
});

export {
  documentPropType,
  qesdocumentPropType,
};
