import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import _ from 'lodash';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { fastGedOpen } from 'generic/core/ged/actions';
import { cleanupResultsComplete, fetchResultsComplete } from 'generic/core/search/actions';
import ResultsComplete from 'generic/components/pages/ResultsComplete';
import ResultsCompleteSkeleton from 'generic/components/skeletons/ResultsCompleteSkeleton';
import { toggleResultsCompleteVisibilityInResults } from 'generic/core/ux/actions';
import LoadingOverlay from 'generic/components/ui/LoadingOverlay';
import { getDocumentBaseId } from 'generic/utils/qesUtils';

const { ETAT_DOCUMENT, COMPLETE_ON_RESULTS_FULL_PAGE } = QES_CONSTANTS;

const ResultsCompleteContainer = ({ disableTagsRefine }) => {
  const bases = useSelector((state) => state.config.bases);
  const {
    base: activeBaseId,
    champs: activeBaseFields,
  } = useSelector((state) => state.config.activeBase);
  const urlApiSummarize = useSelector((state) => state.config.settings.urlApiSummarize_566);
  const loadingComplete = useSelector((state) => state.search.loadingComplete);
  const resultsComplete = useSelector((state) => state.search.resultsComplete);
  const resultsCompleteVisibleInResults = useSelector((state) => state.ux.resultsCompleteVisibleInResults);
  const loadingResults = useSelector((state) => state.search.loading);
  const results = useSelector((state) => state.search.results);
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const handleClose = () => {
    dispatch(cleanupResultsComplete());
  };

  useEffect(() => {
    if (COMPLETE_ON_RESULTS_FULL_PAGE
      && location.pathname.startsWith('/search/results')
      && !smallerThanLarge
      && !results.qaReponse
    ) {
      if (!resultsCompleteVisibleInResults) {
        dispatch(toggleResultsCompleteVisibilityInResults(true));
      }
      if (_.isEmpty(_.get(resultsComplete, 'documents', [])) && !loadingComplete && !_.isEmpty(results.documents)) {
        dispatch(fetchResultsComplete(results.documents[0].idext));
      }
    } else if (resultsCompleteVisibleInResults) {
      dispatch(toggleResultsCompleteVisibilityInResults(false));
      handleClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, resultsCompleteVisibleInResults, smallerThanLarge, location.pathname, results.qaReponse]);

  if (_.isEmpty(_.get(resultsComplete, 'documents', []))) {
    if (loadingComplete && !resultsCompleteVisibleInResults) {
      return <LoadingOverlay />;
    }

    if (resultsCompleteVisibleInResults && (loadingResults || !_.isEmpty(results.documents))) {
      return <ResultsCompleteSkeleton />;
    }

    return null;
  }

  const document = resultsComplete.documents[0];
  const documentBase = _.find(bases, { base: getDocumentBaseId(document) });

  const showButtons = _.isEmpty(matchPath(location.pathname, { path: ['/carts', '/newsletters'] }));

  return (
    <ResultsComplete
      activeBaseFields={activeBaseFields}
      canAddToCart={documentBase.panier}
      canAddToNewsletter={documentBase.newsletter}
      canComment={documentBase.commentaire}
      canEditInGED={documentBase.gedModification}
      canFastEditInGED={documentBase.avecGedRapide}
      canDeleteDocument={_.find(resultsComplete.actions, { etat: ETAT_DOCUMENT.SUPPRIMER }) !== undefined}
      canSendToFriend={documentBase.envoi}
      canSummarize={!_.isEmpty(urlApiSummarize)}
      handleClose={handleClose}
      loadingComplete={loadingComplete}
      resultsComplete={resultsComplete}
      showButtons={showButtons}
      openFastGed={() => dispatch(fastGedOpen(document.idext, document.base, 'result-complete'))}
      titleFieldName={documentBase.champTitre}
      withMoreLikeThis={documentBase.avecMoreLikeThis}
      variant={resultsCompleteVisibleInResults ? 'permanent' : 'temporary'}
      disableTagsRefine={disableTagsRefine || activeBaseId !== document.base || !_.isEmpty(results.qaReponse)}
    />
  );
};

ResultsCompleteContainer.propTypes = {
  disableTagsRefine: PropTypes.bool,
};

ResultsCompleteContainer.defaultProps = {
  disableTagsRefine: false,
};

export default ResultsCompleteContainer;
